import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';
import './styles/shapyaMaxWidth.css';

import { Grid } from '@material-ui/core';
import React from 'react';
import ShapyaFormBodyLeftSide from './ShapyaFormBodyLeftSide';
import ShapyerFormBodyRightSideForm from './ShapyerFormBodyRightSideForm';

const ShapyerFormBody = () => {
  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        direction="row"
        justify="center"
        xs={12}
        className="shapyerFormMainGrid"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={4}
          className="shapyaBgMediumRed shapyaWhite shapyerFormLeftGrid"
        >
          <ShapyaFormBodyLeftSide />
        </Grid>
        <Grid item xs={12} sm={10} md={6} className="shapyerFormRightGrid">
          <ShapyerFormBodyRightSideForm />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShapyerFormBody;
