import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';

import { Box, Button, Fab, Grid, Hidden, Typography } from '@material-ui/core';
import React, { Component, useState } from 'react';

import LanguageDetector from 'i18next-browser-languagedetector';
import { Link } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import ShapyaSpinner from '../images/shapya_spinner.gif';
import TextField from '@material-ui/core/TextField';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const recaptchareq = () => {
  const recaptchares = document.querySelector('#g-recaptcha-response');

  if (recaptchares) {
    recaptchares.setAttribute('required', 'required');
  }
};

const ShapyerFormBodyRightSideForm = () => {
  const { t } = useTranslation();
  const onChangeLanguage = () => {};

  function onChange(value) {
    console.log('Captcha value:', value);
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'default_service',
        'shapyer_contact_form',
        e.target,
        'user_OWsnyBPupik9scReHvsc5',
      )
      .then(
        (result) => {
          if (document.getElementById('iduploadphoto_msg') == '') {
            document.getElementById('iduploadphoto_msg').style.display = '';
          }
          console.log(result.text);
          setTimeout(function () {
            document.getElementById('sending_button').style.display = 'none';
          }, 100);
          setTimeout(function () {
            document.getElementById('formloader').style.display = '';
          }, 100);
          setTimeout(function () {
            document.getElementById('linktm').click();
          }, 3500);
        },
        (error) => {
          console.log(error.text);
        },
      );
    e.target.reset();
  }

  return (
    <div>
      <h1
        className="shapyaMediumRed"
        style={{ fontSize: 'calc(26px + 6 * ((100vw - 320px) / 680))' }}
      >
        <b>{t('form.rightside.title')}</b>
      </h1>
      <br />
      <Typography className="shapyaDarkGrey">
        {t('form.rightside.text1')}
      </Typography>
      <br />
      <Typography className="shapyaDarkGrey">
        <b>{t('form.rightside.text2')}</b>
      </Typography>
      <br />
      <form
        className="contact-form"
        enctype="multipart/form-data"
        method="post"
        onSubmit={sendEmail}
      >
        <Grid container spacing={2}>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_first_name"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightside.firstnamelabel')}
                placeholder={t('form.rightside.firstnameplaceholder')}
                inputProps={{ maxLength: 16, pattern: '[^0-9]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_last_name"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightside.lastnamelabel')}
                placeholder={t('form.rightside.lastnameplaceholder')}
                inputProps={{ maxLength: 16, pattern: '[^0-9]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_email"
                fullWidth
                autoComplete="none"
                required
                type="email"
                label={t('form.rightside.emaillabel')}
                placeholder={t('form.rightside.emailplaceholder')}
                inputProps={{
                  maxLength: 30,
                  pattern:
                    '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_phone_number"
                fullWidth
                autoComplete="on"
                required
                label={t('form.rightside.phonenumberlabel')}
                placeholder={t('form.rightside.phonenumberplaceholder')}
                inputProps={{ maxLength: 24, pattern: '[^=?\'"]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_address"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightside.addresslabel')}
                placeholder={t('form.rightside.addressplaceholder')}
                inputProps={{ maxLength: 45, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_zip_code"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightsidestores.zipcodelabel')}
                placeholder={t('form.rightsidestores.zipcodeplaceholder')}
                inputProps={{ maxLength: 12, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_city"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightside.citylabel')}
                placeholder={t('form.rightside.cityplaceholder')}
                inputProps={{ maxLength: 35, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={6}>
            <Box m={1} className="mibox" align="left">
              <TextField
                name="shapyer_state"
                fullWidth
                autoComplete="none"
                required
                label={t('form.rightsidestores.statelabel')}
                placeholder={t('form.rightsidestores.stateplaceholder')}
                inputProps={{ maxLength: 35, pattern: '[^;*=]*' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item className="migrid" xs={12}>
            <Box m={1} className="mibox" align="center">
              <br />
              <ReCAPTCHA
                sitekey="6Lc0WWwaAAAAADbTnntpfueo8PdvKyGpVMqiX4mG"
                onChange={onChange}
                hl="en"
              />
              <Box color="#808080" id="recaptchafield">
                <small>
                  <b>{t('form.rightside.recaptchamsg')}</b>
                </small>
              </Box>
              <br />
              <br />
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                disableElevation
                size="large"
                className="formbutton_submit"
                id="sending_button"
                onClick={recaptchareq}
              >
                <h3>{t('form.rightside.sendbutton')}</h3>
              </Button>
              <Link to="/thanksMessage/" id="linktm"></Link>
              <p style={{ display: 'none' }} id="formloader">
                <img src={ShapyaSpinner} width="120" />
              </p>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ShapyerFormBodyRightSideForm;
