import '../i18n';

import Footer from '../components/Footer';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import SEO from '../components/seo';
import ShapyerFormBody from '../components/ShapyerFormBody';
import { useTranslation } from 'react-i18next';

const ShapyerForm = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <SEO
        title={t('seo.page.shapyerForm.title')}
        description={t('seo.page.shapyerForm.description')}
        keywords={[
          'shapya',
          'shopping',
          'delivering',
          'groceries',
          'meals',
          'food truck',
          'stores',
          'restaurants',
        ]}
      />
      <NavbarWhite />
      <ShapyerFormBody />
      <Footer />
    </React.Fragment>
  );
};

export default ShapyerForm;
